/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The stats tell the story of California’s hearing situation. Around 10 percent of Californians will suffer from serious hearing loss at some stage in their lives, which means that a grand total of 3 million Californians currently or will suffer from the condition."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By the time Californians hit age 65, that figure rises to 33 percent which poses major problems for active seniors who are seeking to make the most of their post-work lives. But, it’s also an issue for California’s workers. Stats suggest that 60 percent of those suffering from hearing loss are working or going to school, which makes everyday life harder for the state’s teachers, media professionals, chefs, law enforcement officers – in other words, the people that make California tick."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, as you can see, losing your hearing is a common affliction in the Golden State. The question is, if you are starting to experience hearing loss, what can be done about it?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "californias-unique-hearing-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#californias-unique-hearing-problems",
    "aria-label": "californias unique hearing problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "California's Unique Hearing Problems"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "California is a state for outdoor lovers that’s great for the person who walks the trails in Yosemite, skis at Lake Tahoe or surfs on the beaches in Santa Barbara."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s also a state full of music and film lovers with thousands of thriving businesses, and is a laid-back place where socializing with friends is a no brainer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What is it that binds these aspects of California’s culture? The way that Californians can hear nature and each other. However, if you can’t hear warnings from lifeguards, the beach can become hazardous. There is also the everyday need to hear radio traffic updates, take business calls and hear presentations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Think about your own lifestyle. If your hearing has started to deteriorate, are you noticing it in the things you love to do? Can you still hear the sounds of nature as you walk the trails? Does the music at the Fillmore or the Hollywood Bowl still sound as crisp as it used to? When you share a glass or two of wine in Napa Valley, can you still hear the clink of glasses and the sound of wine glugging in the bottle and – more importantly – can you still converse with friends the way you need to?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Life in California is often very dependent on our senses, and hearing is among the most important. It’s a state where socializing matters, where culture is important and where the sound of life is as vital as the brightness of the state’s sunshine and the taste of the food and wine."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That’s why we deal with many inquiries from Californians seeking assistance with their hearing issues."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-can-help-californians",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-can-help-californians",
    "aria-label": "hearcom can help californians permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hear.com Can Help Californians"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Connecting with our partner network couldn’t be easier. Wherever you live in California, all you have to do is visit our website and fill out our request form. It only takes a few moments and our team will take care of the rest for you. And it’s well worth seeking the assistance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have any doubts about whether you are suffering from hearing loss, consulting a us will help to assess the situation and we will plan a way to treat it. In no time, you’ll be back to enjoying the classic California lifestyle with the mute button turned off this time."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-a-hearing-aid-that-suits-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-a-hearing-aid-that-suits-you",
    "aria-label": "find a hearing aid that suits you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find a Hearing Aid That Suits You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we work with plenty of Californians, allowing them to restore their hearing through the best possible care. We have partnered with a network of hearing loss professionals across the state from San Francisco to San Diego, who can provide expert advice and connect you with the perfect technology to solve your hearing issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This generally means finding a hearing aid that works for you (surgery is only recommended in 5 percent of cases). The way it works is simple. Just sign up through our site and one of our experts will contact you for a free consultation. After that, you can arrange an appointment and visit one of our local Partner Providers, who will help you select and fit you with the best hearing aid. Then, you can take the hearing aid home and enjoy a 45-day, risk-free trial."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, if the device isn’t for you, you can exchange it for something else or return it – whatever works for you. The key thing is that every client receives all the support they need to find the right solution."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
